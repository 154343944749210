#logo-image {
  vertical-align: middle;
  width: $tf-global-logo-width;
}

#navbar {
  .navbar-nav {
    padding-right: 16px;
    .nav-item {
      a, a.nav-link {
        padding: 11px 0 10px 0;
        margin-right: -1px;
        .material-icons {
          font-size: 18px;
          vertical-align: middle;
        }
        span {
          display: block;
          font-weight: normal;
          transform: scale(0.8);
          margin: 1px 0 0;
        }
      }
    }
  }
  .account-menu-trigger {
    position: relative;
    .user-kind {
      position: absolute;
      top: 10px;
      display: none;
      .special-user & {
        display: block;
        right: 8px;
        font-size: 10px;
      }
      .super-user & {
        display: block;
        right: 14px;
        font-size: 14px;
      }
      &::after {
        .special-user & {
          content: '++';
        }
        .super-user & {
          content: '!';
        }
      }
    }
  }
  .btn+.btn {
    margin: 0 0 0 0.4em;
  }
  .account-menu {
    background-color: $color-4 !important;
    min-width: 300px;
    li:first-child > .account-menu-label {
      color: $color-1
    }
    li > .account-menu-label {
      display: block;
      padding: 6px 20px;
      color: $color-8;
      i.material-icons {
        font-size: 22px !important;
        display: inline-block !important;
        vertical-align: middle !important;
      }
      &:hover, &:active, &:focus {
        background-color: $color-11;
      }
    }
    .divider {
      background-color: rgba(0, 0, 0, 0.2);
    }
    .material-icons {
      margin-right: 8px;
    }
    @media (max-width: $common-mobile-breakpoint) {
      position: absolute;
      top: 100%;
      left: auto;
      right: 0;
      display: none;
      background-color: #fff;
      border: 1px solid rgba(0,0,0,.15);
    }
    .navbar-form {
      margin: 0;
      padding: 1em 0;
      width: 300px;
      text-align: center;
      border-top: none;
      // border-bottom: 1px solid #e5e5e5;
      border: none;
      display: none;
      @media (min-width: $common-mobile-breakpoint) {
        display: inline-block;
      }
    }
  }
  .dropdown {
    &.open {
      .account-menu {
        min-width: 220px;
        display: block;
      }
      .navbar-form {
        display: inline-block;
        width: 220px;
      }
    }
  }

  .nav-link, .dropdown-toggle {
    font-size: 10px;
    line-height: 10px;
    width: 56px;
    @media (min-width: $common-mobile-breakpoint) {
      width: 64px;
    }
    text-align: center;
    padding: 0;
  }
  .nav-link a, .dropdown-toggle a {
    padding: 0;
  }
  .header-buttons {
    display: none;
    @media (min-width: $common-mobile-breakpoint) {
      display: inline-block;
    }
  }
}

.navbar-inverse {
  >.container {
    max-width: 1080px;
  }
  background-color: $tf-global-header-bg-color;
  color: $tf-global-header-color;
  border: none;
  margin: 0 auto;
  .navbar-nav>.active>a {
    color: $color-1;
    background-color: $tf-global-header-bg-color;
    &:active, &:focus, &:hover {
      background-color: $tf-background-color;
    }
  }
  .navbar-nav>.nav-item>a {
    &:active, &:focus, &:hover {
      color: $color-1;
      background-color: $tf-background-color;
    }
  }
  // header left button
  .slide-panel-button {
    float: left;
    @media (max-width: $common-mobile-breakpoint) {
      margin-left: -15px;
    }
    .nav-item a {
      padding: 16px 15px 15px 15px;
      .material-icons {
        font-size: 18px;
      }
    }
  }
  .navbar-header {
    float: left;
    // position: absolute;
    // left: 50%;
    // width: 116px;
    // margin-left: -58px;
  }
  // app title
  .navbar-brand {
    @include font_title;
    font-weight: bold;
    margin: 0 auto;
    position: relative; //for display build version
    &:hover, &:active, &:focus {
      color: $tf-global-header-color;
      opacity: 0.8;
    }
  }
  .navbar-nav>li>a {
    font-weight: bold;
    @include font_system;
  }
}
#environment {
  display: inline-block;
  font-weight: normal;
  font-size: 10px;
  font-family: sans-serif;
  position: absolute;
  right: 0;
  top: 27px;
  transform: scale(0.8);
}

@media (max-width: $common-mobile-breakpoint) {
  .navbar-header {
    float: left;
  }
  .navbar-nav {
    margin: 0;
    > li {
      float: left;
    }
  }
  .navbar-right {
    float: right;
    margin-right: -15px;
  }
}
