@import "./variables";

.signup-form-embed {
  margin: 1.4em 0 0;
}
#login-dialog {
  .modal-dialog {
    .modal-body {
      input.form-control {
        min-width: 240px;
      }
      .login-buttons {
        text-align: center;
        .btn {
          min-width: 96px;
        }
        .btn+.btn {
            margin: 0 0 0 0.4em;
        }
      }
    }
  }
  .login-failed {
    margin: 20px 0 0 0;
  }
}
#signup-dialog {
  .modal-dialog {
  }
  .modal-body {
    .has-signup-button {
      @media (max-width: $common-mobile-breakpoint) {
        padding-left: 0;
      }
    }
    .signup-message {
      margin: 1em 0 0;
    }
  }
}

.auth-buttons {
  $height: 50px;
  height: $height;
  > form {
    width: 100%;
    text-align: center;
    background-color: $tf-color-yellow;
    $height: 42px;
    position: fixed;
    z-index: 10;
    line-height: $height;
    padding-bottom: 8px;
    button {
      margin: 0 4px;
    }
  }
}
