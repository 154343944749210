@import 'variables';

html {
	font-size: 15px;
}
body {
	font-size: 100%;
	font-family: $font-family-normal;
	line-height: 1.6;
  color: $color-8;
	font-weight: 500;
  font-kerning: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
h3, .h3 {
  font-size: 20px;
}

.btn {
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  z-index: 0;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: top;
  vertical-align: -webkit-baseline-middle;
  transition: background-color, opacity 100ms linear;
  font-kerning: none;
  border-radius: 2px;
	&[disabled] {
		background-color: initial;
    border-color: initial;
    opacity: 0.4;
		pointer-events: none
	}
	box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}

.btn-default {
	//border: 1px solid $color-1;
  border: 0;
  background-color: $tf-color-primary;
	//background-color: $color-2;
	color: $tf-color-primary-text;
	.icon {
		color: $tf-color-primary-text !important;
		background-color: transparent !important;
		// &.icon-googleplus, &.icon-facebook {
		// 	color: $color-2 !important;
		// 	background-color: $color-1 !important;
		// }
	}
  &:hover, &:active, &:focus {
  	// border-color: $color-1 !important;
    color: $tf-color-primary-text;
  	background-color: lighten($tf-color-primary, 5%) !important;
  }
}

.btn-success, .btn-primary {
  border-radius: 2px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: $tf-color-primary;
  color: $tf-color-primary-text;
  border: 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1), background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), color 300ms cubic-bezier(0.4, 0, 0.2, 1);
  &:hover, &:active, &:focus {
    background-color: lighten($tf-color-primary, 5%);
    color: $tf-color-primary-text;
  }
}
.btn-success[disabled] {
  color: $color-1;
  background-color: $color-11;
}


.category {
	h4 {
		font-weight: normal;
	}
}

.modal-header {
	border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom: 1px solid rgba(#000, 0.5);
	background-color: $color-3;
	.modal-title {
		color: $color-8;
	}
  .close {
  	color: $color-8;
  	text-shadow: none;
  	opacity: 1;
		font-weight: normal;
  }
}

.modal-content {
	border-radius: 2px;
	background-color: transparent;
	a {
		color: $color-1;
		&:hover, &:active, &:focus {
			color: rgba($color-1, 0.8);
		}
	}
}
.modal-body {
  background-color: $color-2;
}
.modal-footer {
	border-top: none;
	background-color: $color-3;
}

.form-control {
	color: #333;
	background-color: transparent;
	border-radius: 2px !important;
	&:focus {
    border-color: $color-1;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba($color-1	,.075), 0 0 8px rgba($color-1, .6);
	}
	&[disabled] {
		background-color: $color-2 !important;
		opacity: 0.4;
	}
}

.bootstrap-switch {
	border-radius: 2px !important;
	border-color: $color-2 !important;
}
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  background-color: #24961a;
}

.panel-default {
	border: none;
	border-radius: 2px;
	background-color: $color-4;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
	.panel-heading {
		border-radius-top-left: 2px;
		border-radius-top-right: 2px;
	}
}
.list-group-item {
	background-color: $color-4;
	border: 1px solid $color-14;
}

a {
	color: $color-8;
	&:hover, &:active, &:focus {
		color: rgba($color-8, 0.8);
	}
}

pre {
	background-color: $color-4 !important;
	color: $color-8;
	white-space: pre-wrap;
	border-radius: 2px;
}

.alert {
	border: none;
	background-color: transparent;
  a:not(.btn) {
    color: $color-1;
    text-decoration: underline;
    &:hover, &:active, &:focus {
      color: rgba($color-1, 0.8);
    }
  }
}
.alert-success {
  color: $color-1;
}
.alert-info {
	color: $color-1;
}
.alert-danger {
  color: red;
}
