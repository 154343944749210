@import './variables';

.modal-header {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: $color-5;
}
.modal-footer {
  background-color: $color-5;
}
.modal-title {
  font-weight: normal;
  color: $tf-color-black;
}

#login-dialog, #signup-dialog {
  label {
    font-weight: normal;
  }
  .modal-footer {
    font-size: 0.9rem;
    padding: 10px;
  }
}

*::-webkit-scrollbar {
  /*overflow: hidden;*/
  width: 3px;
}
*::-webkit-scrollbar-button {
  display: none;
}
*::-webkit-scrollbar-thumb {
  background: rgba($color-6, 0.2);
  border-radius: 8px;
}
*::-webkit-scrollbar-track-piece {
  background: transparent;
}