/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */

// App, PC, Mail 共通スタイル

// new techfeed styles
$color-1: #47b89a; // key-color
$color-2: #f8f8f8; // bg-body
$color-3: #333333; // bg-header
$color-4: #fff; // bg-container
$color-5: #f8f8f8; // bg-tab
$color-6: #646464; // bg-icon
$color-7: #EB2142; // bg-icon-act
$color-8: #333; // c-article-title
$color-9: #333; // c-article-desc
$color-10: #383838; // c-article-meta
$color-11: #c8dcff; // focus, hover color
$color-12: #c8dcff; // button background-color (for monolith design)
$color-13: #333333; // bg-lightgray
$color-14: #ebebeb; // border-color
$color-15: #2d533c;

// base color
$tf-color-1: #57a300; // アクセントカラー（黒背景） secondary
$tf-color-2: #417505; // アクセントカラー（白背景） primary
$tf-color-3: #333; // ヘッダなどの背景色
$tf-color-4: rgba(#d7d7d7, 0.4); // ボーダーカラー
$tf-color-5: #333; //基本のテキストカラー（白背景）
$tf-color-6: #fff; // テキストカラー（黒背景）
$tf-color-7: #eee; // カテゴリ名の背景
$tf-color-8: #9b9b9b; // リアクション等
$tf-color-9: #fff; // コンテンツの背景色
$tf-color-10: #666; // メタ系情報の薄めのテキストカラー
$tf-color-11: #000; // PC版ヘッダー色など
$tf-color-12: #2d4d08; // アクティブカラー rgb(45, 77, 8)
$tf-color-13: #f4f4f4; // デスクトップの背景カラー

// $color name
$tf-color-primary: $tf-color-2;
$tf-color-primary-text: $tf-color-6;
$tf-color-secondary: $tf-color-1;
$tf-color-secondary-contrast: $tf-color-5;
$tf-color-light: $tf-color-13;
$tf-color-dark: $tf-color-5;
$tf-color-danger: #f53d3d;
$tf-color-warn: #FBC01E;

$tf-color-twitter: #55acee;
$tf-color-facebook: #3b5998;
$tf-color-google: #dd4b39;
$tf-color-github: #767676;
$tf-color-pocket: #ed4055;
$tf-color-hatena: #008fde;

// 全体
$tf-text-color: $tf-color-5;
$tf-meta-text-color: $tf-color-10;
$tf-background-color: $tf-color-7;
$tf-border-color: $tf-color-4;
$tf-border-color2: $tf-color-8;
$tf-highlight-color: darken($tf-color-1, 10%);
$tf-dark-bg-color: $tf-color-3;
$tf-dark-text-color: $tf-color-6;
$tf-dark-highlight-text-color: $tf-color-1;
$tf-dark-border-color: lighten($tf-dark-bg-color, 10%);
$tf-bg-color-desktop: $tf-color-13;

$tf-desktop-nav-width: 620px;

// font
$tf-font-family-base: -apple-system, "Helvetica Neue", Roboto, sans-serif; // フォールバックフォント
$tf-font-family-default: HiraginoSans-W4, $tf-font-family-base; // デフォルト
$tf-font-family-emphasis-text: HiraKakuPro-W6, $tf-font-family-base; // タイトル等の強調テキスト
$tf-font-family-normal-text: HiraKakuPro-W3, $tf-font-family-base; // メタ情報等に利用する細かいテキスト

// icon
$tf-icon-color: $tf-color-8;
$tf-icon-color-primary: $tf-color-primary;
$tf-icon-color-secondary: $tf-color-secondary;
$tf-icon-size: 16px;

// メニュー
$tf-global-logo-width: 133px;
$tf-global-menu-bg-color: $tf-color-3;
$tf-global-menu-color: $tf-color-1;
$tf-global-menu-bg-color-active: $tf-color-12;
$tf-global-menu-color-active: $tf-color-1;
$tf-global-menu-border-color-active: $tf-color-1;

// サイドバー
$tf-sidebar-border-color: $tf-color-4;

// ヘッダー
$tf-global-header-bg-color: $color-4;
$tf-global-header-color: $tf-color-11;

$tf-header-bg-color: $tf-dark-bg-color;
$tf-header-border-color: $tf-dark-bg-color;
$tf-header-text-color: $tf-dark-highlight-text-color;
$tf-header-title-text-color: $tf-dark-highlight-text-color;

// 左メニュー
$tf-left-menu-min-width: 295px;

// コンテンツ
$tf-content-header-bg-color: $tf-color-7;
$tf-content-bg-color: $tf-color-9;

// 下タブ
$tf-tabbar-color: $tf-color-6;
$tf-tabbar-color-active: $tf-color-1;
$tf-tabbar-bg-color: $tf-color-3;

// 上タブ
$tf-tab-header-height: 52px;
$tf-tab-button-bg-color: $tf-color-3;
$tf-tab-button-color: $tf-color-1;
$tf-tab-button-border-color: rgba($tf-color-1, 0.25);
$tf-tab-button-bg-color-active: rgba($tf-color-12, 0.33);
$tf-tab-button-color-active: $tf-color-1;
$tf-tab-button-border-color-active: rgba($tf-color-1, 0.75);

// ボタン
$tf-button-secondary-bg-color-active: rgba($tf-color-12, 0.6);

// タイムラインタブタイトル
$tf-timeline-title-height: 52px;
$tf-timeline-title-bg-color: $tf-color-7;
$tf-timeline-title-color: $tf-color-5;

// エントリー
$tf-entry-bg-color: $tf-color-9;
$tf-entry-title-color: $tf-color-3;
$tf-entry-description-color: $tf-color-3;
$tf-entry-rank-color: $tf-color-3;
$tf-entry-rank-border-color: $tf-color-3;
$tf-entry-rank-color-s: $tf-color-2;
$tf-entry-rank-border-color-s: $tf-color-2;
$tf-entry-domain-color: $tf-color-3;
$tf-entry-channel-bg-color: $tf-color-7;
$tf-entry-channel-color: $tf-color-5;
$tf-entry-date-color: $tf-color-3;
$tf-entry-recommend-color: $tf-color-3;
$tf-entry-comment-info-color: $tf-color-3;
$tf-entry-action-color: $tf-color-8;
$tf-entry-action-color-active: $tf-color-2;
$tf-entry-action-icon-color: $tf-color-8;
$tf-entry-action-icon-color-active: $tf-color-2;
$tf-entry-border-color: $tf-color-4;
$tf-entry-amp-icon-color: $tf-color-9;
$tf-entry-amp-icon-bgcolor: $tf-color-8;
$tf-entry-recommendation-bg-color: $tf-color-3;
$tf-entry-recommendation-text-color: $tf-color-6;

// エントリー（darkテーマ）
$tf-entry-bg-color-dark: $tf-color-11;
$tf-entry-title-color-dark: $tf-color-6;
$tf-entry-description-color-dark: $tf-color-6;
$tf-entry-rank-color-dark: $tf-color-6;
$tf-entry-rank-border-color-dark: $tf-color-6;
$tf-entry-rank-color-s-dark: $tf-color-1;
$tf-entry-rank-border-color-s-dark: $tf-color-1;
$tf-entry-domain-color-dark: $tf-color-6;
$tf-entry-channel-bg-color-dark: $tf-color-10;
$tf-entry-channel-color-dark: $tf-color-6;
$tf-entry-date-color-dark: $tf-color-6;
$tf-entry-recommend-color-dark: $tf-color-6;
$tf-entry-comment-info-color-dark: $tf-color-6;
$tf-entry-action-color-dark: $tf-color-6;
$tf-entry-action-color-active-dark: $tf-color-1;
$tf-entry-action-icon-color-dark: $tf-color-6;
$tf-entry-action-icon-color-active-dark: $tf-color-1;
$tf-entry-border-color-dark: $tf-color-6;
$tf-entry-amp-icon-color-dark: $tf-color-9;
$tf-entry-amp-icon-bgcolor-dark: $tf-color-10;

// エントリー詳細
$tf-entry-detail-title-font-size: 1.4rem;
$tf-entry-detail-content-bg-color: $tf-color-11;
$tf-related-entry-title-height: 52px;
$tf-related-entry-title-bg-color: $tf-color-7;
$tf-related-entry-title-color: $tf-color-5;

// コメント
$tf-comment-author-name-color: $tf-color-5;
$tf-comment-author-id-color: $tf-color-10;
$tf-comment-meta-color: $tf-color-10;
$tf-comment-text-color: $tf-color-5;

// コメント（darkテーマ）
$tf-comment-author-name-color-dark: $tf-color-6;
$tf-comment-author-id-color-dark: $tf-color-6;
$tf-comment-meta-color-dark: $tf-color-6;
$tf-comment-text-color-dark: $tf-color-6;


// Info Card
// $tf-info-card-header-bg-color: $tf-color-3;
// $tf-info-card-header-color: $tf-color-6;
$tf-info-card-bg-color: linear-gradient(to bottom, #417505, #223f00);
$tf-info-card-header-bg-color: transparent;
$tf-info-card-header-color: $tf-color-6;
$tf-info-card-content-bg-color: transparent;
$tf-info-card-content-color: $tf-color-6;
$tf-info-card-close-button-color: $tf-color-6;

// ボタン
// TODO: あとでデザイン確認
// $tf-button-text-color: $tf-color-5;
// $tf-button-background-color: $tf-color-7;
// $tf-button-border-color: $tf-color-4;

// モーダルダイアログ
$tf-modal-border-color: $tf-color-9;
$tf-modal-header-bg-color: $tf-color-3;
$tf-modal-header-color: $tf-color-1;
$tf-modal-header-icon-color: $tf-color-1;
$tf-modal-footer-border-color: $tf-color-9;
$tf-modal-footer-ok-button-color: $tf-color-1;
$tf-modal-footer-cancel-button-color: $tf-color-6;
$tf-modal-content-bg-color: $tf-color-11;
$tf-modal-content-title-color: $tf-color-6;

// シェア
$tf-share-icon-color: $tf-color-8;

// アバター
$tf-avatar-border-color: $tf-color-4;
$tf-avatar-tabbar-icon-color: $tf-color-10;

// コメント
$tf-comment-input-border-color: $tf-color-4;
$tf-comment-input-bg-color: $tf-color-9;
$tf-comment-input-color: $tf-color-5;

// ポップオーバー
$tf-popover-bg-color: $tf-color-9;

// ダイジェスト
$tf-highlight-header-color: $tf-header-text-color;
$tf-highlight-header-bg-color: $tf-header-bg-color;
$tf-highlight-header-link-color: $tf-color-1;
$tf-highlight-header-meta-text-color: $tf-color-6;
$tf-highlight-category-title-bg-color: $tf-color-7;
$tf-highlight-category-title-color: $tf-color-5;
$tf-highlight-read-more-color: $tf-color-2;
$tf-highlight-category-meta-text-color: $tf-color-2;
$tf-highlight-entry-bg-color: $tf-color-9;
$tf-highlight-bg-color: $tf-color-3;
$tf-highlight-action-color: $tf-color-8;
$tf-highlight-channel-bg-color: $tf-color-7;

// フォーム
$tf-form-border-color: $tf-color-8;

// グループ
// $tf-group-icon-border-color: $tf-color-8;
$tf-group-icon-border-color: transparent;

// 汎用
$tool-chip-bg-color: $tf-color-7;
$tool-chip-color: $tf-color-5;
$tf-toolbar-height: 60px;

@mixin font_title {
  font-family: "Audiowide", sans-serif;
  font-size: 12px;
  line-height: 1;
  color: #28FE14;
}
@mixin font_system {
  font-size: 10px;
  line-height: 1;
  color: #28FE14;
}

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-wide-desktop: 1250px;

// $wide-background-color: #222;
// $wide-content-color: #2c2c2c;
$wide-background-color: $color-2;
$wide-content-color: $color-2;
$wide-content-border-color: $color-14;
$wide-box-shadow-color: $color-4;


$progress-bar-color: $tf-color-primary;
$progress-bar-color-theme-dark: $tf-color-secondary;

$tf-group-menu-width: 220px;
$tf-group-menu-margin: 40px;
$tf-group-content-width: 700px;
$tf-group-background: $tf-color-13;
$tf-group-top-margin: 20px;

$tf-group-cover-bg: linear-gradient(102deg, #d26e00, #e049f9);

// グループ系画面
$group-color-green: #57a300;
$group-color-red: #d0021b;
$group-color-red2: #b01f24;
$group-color-orange: #d26e00;
$group-color-purple: #e049f9;
$group-color-white: #ffffff;
$group-color-white2: #f8f8f8;
$group-color-white3: #f4f4f4;
$group-color-black: #000000;
$group-color-black2: #333333;
$group-color-black3: #4a4a4a;
$group-color-gray: #666666;
$group-color-gray2: #9b9b9b;
$group-color-gray3: #d8d8d8;
$group-color-gray4: #d7d7d7;
$group-color-gray5: #efefef;
$group-color-gray6: #979797;

// 全体
$group-header-bg-color: $group-color-white2;
$group-content-bg-color: $group-color-white3;
$group-border-color: $group-color-gray4;

// テキスト
$group-text-color: $group-color-black2;
$group-text-meta-color: $group-color-black3;
$group-text-opposite-color: $group-color-white;
$group-text-primary-color: $group-color-green;
$group-text-danger-color: $group-color-red;
$group-text-label-color: $group-color-gray;

// アイコン
$group-icon-image-bg-color: $group-color-gray3;
$group-icon-image-border-color: $group-color-white3;
$group-icon-image-border-color2: $group-color-gray6;

// フォーム
$group-form-text: $group-color-black2;
$group-form-label: $group-color-gray;
$group-form-image-upload-section-bg-color: $group-color-gray5;
$group-form-group-border-bg-color: $group-color-gray3;
$group-form-icon-color: $group-color-gray2;

// 特殊
$group-cover-image-bg-image: linear-gradient(116deg, $group-color-orange, $group-color-purple);
// → group.detail.page.ts にてハードコードしている箇所あり
$group-box-shadow: 0 1px 4px 0 rgba($group-color-black, 0.2);
$group-translucent-border: rgba($group-color-white, 0.4);

