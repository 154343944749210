// PC, Mail用のスタイル

@import './app-variables-legacy';

// $color-1: #28FE14; // key-color
// $color-2: #000000; // bg-body
// $color-3: #111111; // bg-header
// $color-4: #191919; // bg-container
// $color-5: #1B1A1B; // bg-tab
// $color-6: #B3B3B3; // bg-icon
// $color-7: #EB2142; // bg-icon-act
// $color-8: #F4F4F4; // c-article-title
// $color-9: #949494; // c-article-desc
// $color-10: #595959; // c-article-meta
// $color-11: #324830; // focus, hover color
// $color-12: #335230; // button background-color (for monolith design)
// $color-13: #333333; // bg-lightgray
// $color-14: #444444; // border-color

$common-header-height: 50px;
// ヘッダーの新規登録、ログインボタン
$common-mobile-breakpoint: 768px;
$common-page-size-large: 960px;
$common-page-size-medium: 768px;
$common-page-size-small: 600px; // mail

// 以前のスタイルから変数名を変えずに中身を入れ替え
$tf-color-yellow: $color-3;  // 以前の黄色
$tf-color-black: $color-1;   // 以前の黒
$tf-color-gray: $color-2;    // 以前の灰色（背景色）
$tf-color-lightgray: $color-10; // 以前の薄い灰色（メタ文字色）
$tf-link-color: #3277b1;
$tf-page-background-color: $tf-color-gray;
$tf-command-focus-color: #ccc;

// navbarの高さ（Bootstrapの変数を参照できるといいのだけど）
$navbar-height: 50px;

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
$screen-xs:                  480px;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px;
$screen-md-min:              $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

// トップページのタブの高さ
$top-tab-height: 35px;

// $font-family-normal: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Open Sans","Helvetica Neue",sans-serif;
$font-family-normal: Roboto, "Helvetica Neue", sans-serif;
$font-family-article-body: Georgia,Cambria,"Times New Roman",Times,serif;

$fixed-footer-size: 47px;

@mixin font_title {
  font-family: "Audiowide", sans-serif;
  font-size: 12px;
  line-height: 1;
  color: $tf-color-11;
}
@mixin font_system {
  font-size: 10px;
  line-height: 1;
  color: $tf-color-black; //#28FE14;
}
