@charset "UTF-8";
@import 'variables';

.browser-not-support {
  display: none;
  background-color: #fcf8e3;
  border-color: #faebcc;
  text-align: left;
  a {
    color: inherit !important;
  }
  dt {
    margin-top: 0.5em;
  }
  dd {
    margin-left: 1em;
  }
  .not-support-mobile {
    display: none;
  }
}

.env-not-support {
  .browser-not-support {
    display: block;
  }
  &.env-mobile {
    .not-support-default {
      display: none;
    }
    .not-support-mobile {
      display: block;
    }
  }
}
