@import 'variables';

.introjs-tooltip {
  color: $color-2;
  a:not(.introjs-button) {
    color: cornflowerblue;
    text-decoration: underline;
  }
}
.introjs-button {
  &:hover {
    color: #333;
  }
}
