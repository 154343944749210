@import 'variables';

@media (min-width: $breakpoint-desktop) {
  html {
    font-size: 15.4px;
  }
}
html.over-desktop {
  font-size: 15.4px;
}

@media (min-width: $breakpoint-wide-desktop) {
  body,
  .modal-header,
  .modal-body,
  .modal-footer,
  .list-group-item {
    background-color: $wide-background-color;
  }
  .card {
    background-color: $wide-content-color;
  }
}

.over-wide-desktop {
  body,
  .modal-header,
  .modal-body,
  .modal-footer,
  .list-group-item {
    background-color: $wide-background-color;
  }
  .card {
    background-color: $wide-content-color;
  }
}
