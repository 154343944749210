@charset "UTF-8";
/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
#logo-image {
  vertical-align: middle;
  width: 133px;
}

#navbar .navbar-nav {
  padding-right: 16px;
}

#navbar .navbar-nav .nav-item a, #navbar .navbar-nav .nav-item a.nav-link {
  padding: 11px 0 10px 0;
  margin-right: -1px;
}

#navbar .navbar-nav .nav-item a .material-icons, #navbar .navbar-nav .nav-item a.nav-link .material-icons {
  font-size: 18px;
  vertical-align: middle;
}

#navbar .navbar-nav .nav-item a span, #navbar .navbar-nav .nav-item a.nav-link span {
  display: block;
  font-weight: normal;
  transform: scale(0.8);
  margin: 1px 0 0;
}

#navbar .account-menu-trigger {
  position: relative;
}

#navbar .account-menu-trigger .user-kind {
  position: absolute;
  top: 10px;
  display: none;
}

.special-user #navbar .account-menu-trigger .user-kind {
  display: block;
  right: 8px;
  font-size: 10px;
}

.super-user #navbar .account-menu-trigger .user-kind {
  display: block;
  right: 14px;
  font-size: 14px;
}

.special-user #navbar .account-menu-trigger .user-kind::after {
  content: '++';
}

.super-user #navbar .account-menu-trigger .user-kind::after {
  content: '!';
}

#navbar .btn + .btn {
  margin: 0 0 0 0.4em;
}

#navbar .account-menu {
  background-color: #fff !important;
  min-width: 300px;
}

#navbar .account-menu li:first-child > .account-menu-label {
  color: #47b89a;
}

#navbar .account-menu li > .account-menu-label {
  display: block;
  padding: 6px 20px;
  color: #333;
}

#navbar .account-menu li > .account-menu-label i.material-icons {
  font-size: 22px !important;
  display: inline-block !important;
  vertical-align: middle !important;
}

#navbar .account-menu li > .account-menu-label:hover, #navbar .account-menu li > .account-menu-label:active, #navbar .account-menu li > .account-menu-label:focus {
  background-color: #c8dcff;
}

#navbar .account-menu .divider {
  background-color: rgba(0, 0, 0, 0.2);
}

#navbar .account-menu .material-icons {
  margin-right: 8px;
}

@media (max-width: 768px) {
  #navbar .account-menu {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    display: none;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

#navbar .account-menu .navbar-form {
  margin: 0;
  padding: 1em 0;
  width: 300px;
  text-align: center;
  border-top: none;
  border: none;
  display: none;
}

@media (min-width: 768px) {
  #navbar .account-menu .navbar-form {
    display: inline-block;
  }
}

#navbar .dropdown.open .account-menu {
  min-width: 220px;
  display: block;
}

#navbar .dropdown.open .navbar-form {
  display: inline-block;
  width: 220px;
}

#navbar .nav-link, #navbar .dropdown-toggle {
  font-size: 10px;
  line-height: 10px;
  width: 56px;
  text-align: center;
  padding: 0;
}

@media (min-width: 768px) {
  #navbar .nav-link, #navbar .dropdown-toggle {
    width: 64px;
  }
}

#navbar .nav-link a, #navbar .dropdown-toggle a {
  padding: 0;
}

#navbar .header-buttons {
  display: none;
}

@media (min-width: 768px) {
  #navbar .header-buttons {
    display: inline-block;
  }
}

.navbar-inverse {
  background-color: #fff;
  color: #000;
  border: none;
  margin: 0 auto;
}

.navbar-inverse > .container {
  max-width: 1080px;
}

.navbar-inverse .navbar-nav > .active > a {
  color: #47b89a;
  background-color: #fff;
}

.navbar-inverse .navbar-nav > .active > a:active, .navbar-inverse .navbar-nav > .active > a:focus, .navbar-inverse .navbar-nav > .active > a:hover {
  background-color: #eee;
}

.navbar-inverse .navbar-nav > .nav-item > a:active, .navbar-inverse .navbar-nav > .nav-item > a:focus, .navbar-inverse .navbar-nav > .nav-item > a:hover {
  color: #47b89a;
  background-color: #eee;
}

.navbar-inverse .slide-panel-button {
  float: left;
}

@media (max-width: 768px) {
  .navbar-inverse .slide-panel-button {
    margin-left: -15px;
  }
}

.navbar-inverse .slide-panel-button .nav-item a {
  padding: 16px 15px 15px 15px;
}

.navbar-inverse .slide-panel-button .nav-item a .material-icons {
  font-size: 18px;
}

.navbar-inverse .navbar-header {
  float: left;
}

.navbar-inverse .navbar-brand {
  font-family: "Audiowide", sans-serif;
  font-size: 12px;
  line-height: 1;
  color: #000;
  font-weight: bold;
  margin: 0 auto;
  position: relative;
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:active, .navbar-inverse .navbar-brand:focus {
  color: #000;
  opacity: 0.8;
}

.navbar-inverse .navbar-nav > li > a {
  font-weight: bold;
  font-size: 10px;
  line-height: 1;
  color: #47b89a;
}

#environment {
  display: inline-block;
  font-weight: normal;
  font-size: 10px;
  font-family: sans-serif;
  position: absolute;
  right: 0;
  top: 27px;
  transform: scale(0.8);
}

@media (max-width: 768px) {
  .navbar-header {
    float: left;
  }
  .navbar-nav {
    margin: 0;
  }
  .navbar-nav > li {
    float: left;
  }
  .navbar-right {
    float: right;
    margin-right: -15px;
  }
}

@font-face {
  font-family: 'AudioWide';
  src: url("../../fonts/Audiowide-Regular.ttf") format("truetype");
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
.signup-form-embed {
  margin: 1.4em 0 0;
}

#login-dialog .modal-dialog .modal-body input.form-control {
  min-width: 240px;
}

#login-dialog .modal-dialog .modal-body .login-buttons {
  text-align: center;
}

#login-dialog .modal-dialog .modal-body .login-buttons .btn {
  min-width: 96px;
}

#login-dialog .modal-dialog .modal-body .login-buttons .btn + .btn {
  margin: 0 0 0 0.4em;
}

#login-dialog .login-failed {
  margin: 20px 0 0 0;
}

@media (max-width: 768px) {
  #signup-dialog .modal-body .has-signup-button {
    padding-left: 0;
  }
}

#signup-dialog .modal-body .signup-message {
  margin: 1em 0 0;
}

.auth-buttons {
  height: 50px;
}

.auth-buttons > form {
  width: 100%;
  text-align: center;
  background-color: #333333;
  position: fixed;
  z-index: 10;
  line-height: 42px;
  padding-bottom: 8px;
}

.auth-buttons > form button {
  margin: 0 4px;
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
#global-message {
  display: none;
  position: absolute;
  top: 0;
  width: 480px;
  margin-left: -240px;
  z-index: 999999;
  left: 50%;
  text-align: center;
  background-color: #c8dcff;
  color: #47b89a;
}

@media (max-width: 768px) {
  #global-message {
    width: 320px;
    margin-left: -160px;
  }
}

.page-title {
  border-bottom: 1px solid rgba(215, 215, 215, 0.4);
  padding-bottom: 0.4em;
  line-height: 1.5;
  font-size: 1.5em;
  color: #333;
}

.page-desc {
  font-size: 1.1em;
  margin: 0.5em 0 1em;
}

body {
  padding-top: 50px;
  padding-bottom: 0px;
  margin: 0 auto;
  background-color: #f4f4f4;
}

html.content-only body {
  padding: 0;
  margin: 0;
}

body, .container {
  /*max-width: 1080px;*/
}

.jumbotron {
  color: #47b89a;
  background-color: #4A4A4A;
}

.jumbotron .top_lead, .jumbotron small {
  color: #333;
}

.jumbotron p {
  font-weight: inherit;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-right: 1rem;
  padding-left: 1rem;
}

/* Custom page header */
.header {
  padding-bottom: 1rem;
  border-bottom: .05rem solid #e5e5e5;
}

.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 3rem;
}

/* Custom page footer */
.footer {
  margin-top: 2em;
  padding: 2em 10px;
  color: #57a300;
  background: #fff;
  text-align: center;
}

.footer .site-footer-links {
  margin: 0 0 1em;
  padding: 0;
  list-style: none;
}

.footer .site-footer-links li {
  display: inline-block;
  line-height: 16px;
  margin: 0;
}

.footer .site-footer-links li a, .footer .site-footer-links li a:visited {
  color: #57a300;
  text-decoration: underline;
  font-size: 12px;
}

.footer .site-footer-links li a:after, .footer .site-footer-links li a:visited:after {
  content: '';
  display: none;
}

.footer .site-footer-links li:after {
  display: inline-block;
  content: '|';
  margin: 0 0.3em;
}

.footer .site-footer-links li:last-child:after {
  display: none;
  content: '';
  margin: 0;
}

.footer small {
  color: #47b89a;
}

.container-narrow > hr {
  margin: 2rem 0;
}

/* Main marketing message and sign up button */
/*.jumbotron {
  text-align: center;
  border-bottom: .05rem solid #e5e5e5;
}*/
/* Supporting marketing content */
.marketing {
  margin: 3rem 0;
}

.marketing p + h4 {
  margin-top: 1.5rem;
}

img {
  max-width: 100%;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 48em) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 2rem;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  /*.jumbotron {
    border-bottom: 0;
  }*/
}

.page-size-medium {
  max-width: 768px;
}

.text-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ellipsis-nowrap {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.material-icons {
  text-rendering: optimizeLegibility;
}

.loading-dialog {
  display: none;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
}

.loading-dialog__icon {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -18px;
  z-index: 1050;
  color: #fff;
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
/**
 * 雑多なCSSを集めておくファイル。
 * どこに分ければいいかわからないものはとりあえずここに書いておいて、カテゴライズできそうなら別ファイルに切り出す。
 */
* {
  box-sizing: border-box;
}

/**
 * target="_blank"なa要素に、自動的にアイコンを付与する
 */
a[target="_blank"]::after {
  margin-left: 2px;
  content: "　";
  display: inline-block;
  width: 14px;
  background: url("../../images/icons/material-design-icons/ic_open_in_new_white_24px.svg") no-repeat;
  background-size: contain;
  vertical-align: middle;
}

a[target="_blank"].no-icon::after {
  display: none;
  content: "";
}

.not-yet-implemented::after {
  content: "(TODO)";
}

.fixed {
  position: fixed;
}

@media (max-width: 768px) {
  a:active {
    background-color: #c8dcff;
  }
}

a:focus {
  text-decoration: none;
}

.margin-top-20 {
  margin-top: 20px;
}

.mail-caution {
  text-align: left;
  margin-bottom: 25px;
  color: #333;
  font-size: 13px;
}

.mail-caution ul, .mail-caution li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
.modal-header {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: #f8f8f8;
}

.modal-footer {
  background-color: #f8f8f8;
}

.modal-title {
  font-weight: normal;
  color: #47b89a;
}

#login-dialog label, #signup-dialog label {
  font-weight: normal;
}

#login-dialog .modal-footer, #signup-dialog .modal-footer {
  font-size: 0.9rem;
  padding: 10px;
}

*::-webkit-scrollbar {
  /*overflow: hidden;*/
  width: 3px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-thumb {
  background: rgba(100, 100, 100, 0.2);
  border-radius: 8px;
}

*::-webkit-scrollbar-track-piece {
  background: transparent;
}

/* social icons */
@font-face {
  font-family: 'social_icons';
  src: url("../../images/social_icons/social_icons.eot");
  src: url("../../images/social_icons/social_icons.eot") format("embedded-opentype"), url("../../images/social_icons/social_icons.woff") format("woff"), url("../../images/social_icons/social_icons.ttf") format("truetype"), url("../../images/social_icons/social_icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'social_icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-evernote:before {
  content: "\e004";
}

.icon-feedly:before {
  content: "\e007";
}

.icon-pocket:before {
  content: "\e008";
}

.icon-line:before {
  content: "\e009";
}

.icon-hatena:before {
  content: "\e00a";
}

.icon-feedly-square:before {
  content: "\e601";
}

.icon-googleplus:before {
  content: "\e608";
}

.icon-facebook:before {
  content: "\e60d";
}

.icon-instagram:before {
  content: "\e610";
}

.icon-twitter:before {
  content: "\e611";
}

.icon-feed:before {
  content: "\e614";
}

.icon-youtube:before {
  content: "\e617";
}

.icon-flickr2:before {
  content: "\e61e";
}

.icon-githubmark:before {
  content: "\e626";
}

.icon-github:before {
  content: "\e627";
}

.icon-wordpress:before {
  content: "\e629";
}

.icon-tumblr:before {
  content: "\e62d";
}

.icon-yahoo:before {
  content: "\e62f";
}

.icon-apple:before {
  content: "\e631";
}

.icon-android:before {
  content: "\e633";
}

.icon-windows:before {
  content: "\e634";
}

.icon-windows8:before {
  content: "\e635";
}

.icon-skype:before {
  content: "\e636";
}

.icon-delicious:before {
  content: "\e638";
}

.icon-pinterest:before {
  content: "\e63a";
}

.icon-pushpin:before {
  content: "\e900";
}

.social-login-btn {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .social-login-btn {
    padding: 0 5px;
  }
}

.social-login-btn .btn {
  text-align: left;
  line-height: 2em;
  width: 100%;
}

.social-login-btn .icon {
  margin: 0 8px;
  padding: 4px;
  font-size: 20px;
}

.social-login-btn.facebook .icon {
  color: #fff;
  background-color: #3B5998;
  font-size: 14px;
  margin: 2px 10px;
}

.social-login-btn.google .icon {
  color: #fff;
  background-color: #DD4B39;
  font-size: 14px;
  border-radius: 16px;
  margin: 2px 10px;
}

.social-login-btn.github .icon {
  color: #333;
  background-color: #transparent;
}

.social-login-btn.twitter .icon {
  color: #28A9E0;
  background-color: #transparent;
}

.social-login-btn.pocket .icon {
  color: #DD4B39;
  background-color: #transparent;
}

.social-login-btn.hatena .icon {
  color: #2C6DBD;
  background-color: #transparent;
  font-size: 19px;
}

.or-line {
  border-color: #646464;
  margin: 24px 0;
}

.or-line::before {
  color: #646464;
  content: "OR";
  display: block;
  width: 40px;
  margin-left: -20px;
  position: relative;
  top: -11px;
  left: 50%;
  background: #f8f8f8;
  font-size: 14px;
  text-align: center;
}

@media (min-width: 1250px) {
  .or-line::before {
    background-color: #f8f8f8;
  }
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
html {
  font-size: 15px;
}

body {
  font-size: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  line-height: 1.6;
  color: #333;
  font-weight: 500;
  font-kerning: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3, .h3 {
  font-size: 20px;
}

.btn {
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  z-index: 0;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: top;
  vertical-align: -webkit-baseline-middle;
  transition: background-color, opacity 100ms linear;
  font-kerning: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.btn[disabled] {
  background-color: initial;
  border-color: initial;
  opacity: 0.4;
  pointer-events: none;
}

.btn-default {
  border: 0;
  background-color: #417505;
  color: #fff;
}

.btn-default .icon {
  color: #fff !important;
  background-color: transparent !important;
}

.btn-default:hover, .btn-default:active, .btn-default:focus {
  color: #fff;
  background-color: #4f8d06 !important;
}

.btn-success, .btn-primary {
  border-radius: 2px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: #417505;
  color: #fff;
  border: 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1), background-color 300ms cubic-bezier(0.4, 0, 0.2, 1), color 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-success:hover, .btn-success:active, .btn-success:focus, .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: #4f8d06;
  color: #fff;
}

.btn-success[disabled] {
  color: #47b89a;
  background-color: #c8dcff;
}

.category h4 {
  font-weight: normal;
}

.modal-header {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #333333;
}

.modal-header .modal-title {
  color: #333;
}

.modal-header .close {
  color: #333;
  text-shadow: none;
  opacity: 1;
  font-weight: normal;
}

.modal-content {
  border-radius: 2px;
  background-color: transparent;
}

.modal-content a {
  color: #47b89a;
}

.modal-content a:hover, .modal-content a:active, .modal-content a:focus {
  color: rgba(71, 184, 154, 0.8);
}

.modal-body {
  background-color: #f8f8f8;
}

.modal-footer {
  border-top: none;
  background-color: #333333;
}

.form-control {
  color: #333;
  background-color: transparent;
  border-radius: 2px !important;
}

.form-control:focus {
  border-color: #47b89a;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(71, 184, 154, 0.075), 0 0 8px rgba(71, 184, 154, 0.6);
}

.form-control[disabled] {
  background-color: #f8f8f8 !important;
  opacity: 0.4;
}

.bootstrap-switch {
  border-radius: 2px !important;
  border-color: #f8f8f8 !important;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  background-color: #24961a;
}

.panel-default {
  border: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.panel-default .panel-heading {
  border-radius-top-left: 2px;
  border-radius-top-right: 2px;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #ebebeb;
}

a {
  color: #333;
}

a:hover, a:active, a:focus {
  color: rgba(51, 51, 51, 0.8);
}

pre {
  background-color: #fff !important;
  color: #333;
  white-space: pre-wrap;
  border-radius: 2px;
}

.alert {
  border: none;
  background-color: transparent;
}

.alert a:not(.btn) {
  color: #47b89a;
  text-decoration: underline;
}

.alert a:not(.btn):hover, .alert a:not(.btn):active, .alert a:not(.btn):focus {
  color: rgba(71, 184, 154, 0.8);
}

.alert-success {
  color: #47b89a;
}

.alert-info {
  color: #47b89a;
}

.alert-danger {
  color: red;
}

.highlight .nav-tabs {
  background: #f3f3f3;
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
.introjs-tooltip {
  color: #f8f8f8;
}

.introjs-tooltip a:not(.introjs-button) {
  color: cornflowerblue;
  text-decoration: underline;
}

.introjs-button:hover {
  color: #333;
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
.browser-not-support {
  display: none;
  background-color: #fcf8e3;
  border-color: #faebcc;
  text-align: left;
}

.browser-not-support a {
  color: inherit !important;
}

.browser-not-support dt {
  margin-top: 0.5em;
}

.browser-not-support dd {
  margin-left: 1em;
}

.browser-not-support .not-support-mobile {
  display: none;
}

.env-not-support .browser-not-support {
  display: block;
}

.env-not-support.env-mobile .not-support-default {
  display: none;
}

.env-not-support.env-mobile .not-support-mobile {
  display: block;
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
@media (min-width: 1024px) {
  html {
    font-size: 15.4px;
  }
}

html.over-desktop {
  font-size: 15.4px;
}

@media (min-width: 1250px) {
  body,
  .modal-header,
  .modal-body,
  .modal-footer,
  .list-group-item {
    background-color: #f8f8f8;
  }
  .card {
    background-color: #f8f8f8;
  }
}

.over-wide-desktop body,
.over-wide-desktop .modal-header,
.over-wide-desktop .modal-body,
.over-wide-desktop .modal-footer,
.over-wide-desktop .list-group-item {
  background-color: #f8f8f8;
}

.over-wide-desktop .card {
  background-color: #f8f8f8;
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
/**
# スタイルの指定方法について

## 色

従来通り `$tf-color-x` を利用しますが、中身は CSS Variables に書き換わっています。
色の定義が見たい場合は、 `_app.globals.scss` を確認してください。

`$tf-text-color` 等のエイリアスを使っても構いません。使っていない定義は将来的に整理する予定です。

## フォント

特にデザインで特別なフォント指定がない限り、フォントのまま変更する必要はありません。
デフォルトでは、"HiraginoSans-W3"、boldでは、"HiraginoSans-W6"が使われるようにマッピングしてあります。
W3、W6以外の太さで、例えばW4を使う場合は、 `font-wight` プロパティで500を指定してください（数字3桁にして+100）。

font-weightとのマッピング（100小さいのは、W3を `normal` に、W6を `bold` に対応したいため）
W0 → 100
W1 → 200
W2 → 300
W3 → 400
...
W8 → 900

※ W9 を利用する場合は別途、検討する
**/
/**
# スタイルの指定方法について

## 色

従来通り `$tf-color-x` を利用しますが、中身は CSS Variables に書き換わっています。
色の定義が見たい場合は、 `_app.globals.scss` を確認してください。

`$tf-text-color` 等のエイリアスを使っても構いません。使っていない定義は将来的に整理する予定です。

## フォント

特にデザインで特別なフォント指定がない限り、フォントのまま変更する必要はありません。
デフォルトでは、"HiraginoSans-W3"、boldでは、"HiraginoSans-W6"が使われるようにマッピングしてあります。
W3、W6以外の太さで、例えばW4を使う場合は、 `font-wight` プロパティで500を指定してください（数字3桁にして+100）。

font-weightとのマッピング（100小さいのは、W3を `normal` に、W6を `bold` に対応したいため）
W0 → 100
W1 → 200
W2 → 300
W3 → 400
...
W8 → 900

※ W9 を利用する場合は別途、検討する
**/
/**
  * ハイライトやタイムラインのヘッダーのボーダー
  */
.comments {
  margin-bottom: 8px;
  position: relative;
}

.comments .comments-header {
  position: absolute;
  top: -24px;
  margin: 0;
  padding: 4px 12px;
  border-bottom: none;
  height: 24px;
  line-height: 16px;
  background-color: var(--tf-color-2);
  font-size: 11px;
  font-weight: bold;
}

.comments .comment-list {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.comments .comment-list .comment-listitem {
  margin-bottom: 1px;
}

.comments a {
  text-decoration: none !important;
}

.comment {
  position: relative;
  background: var(--tf-color-2);
  padding: 8px;
  border-top: 1px solid #d7d7d766;
}

.comment .comment-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.compact .comment .comment-container {
  padding-right: 0;
}

.comment .comment-container > .avatar {
  margin-top: 4px;
}

.comment .comment-container > .avatar .avatar-icon {
  display: block;
  width: 32px;
  height: 32px;
  max-width: none;
}

.comment .comment-container > .arrow-mark {
  flex-basis: 32px;
  align-self: center;
  text-align: center;
}

.comment .content {
  flex-grow: 1;
  margin: 4px　8px;
  margin-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.comment .content .content-header {
  display: flex;
  align-items: center;
  margin: 0 0 4px;
}

.comment .content .content-header .author-name {
  display: flex;
  align-items: center;
  text-align: left;
  flex: 1 0 auto;
  overflow-x: hidden;
}

.comment .content .content-header .author-name .display-name {
  font-size: 12px;
  color: var(--tf-color-15);
  font-weight: bold;
  display: inline-block;
}

.comment .content .content-header .author-name .username {
  font-size: 12px;
  color: var(--tf-color-entry-text-secondary);
  display: inline-block;
  margin: 0 0 0 4px;
}

.comment .content .content-header .updated-at {
  font-size: 10px;
  color: var(--tf-color-entry-text-secondary);
  flex: 0 0 auto;
  text-align: right;
}

.comment .content .content-header .action-button {
  height: 14px;
  width: 20px;
  flex: 0 0 20px;
  color: #333;
  font-size: 1.4rem;
  margin-top: 1px;
}

.comment .content .comment-body {
  font-family: HiraKakuPro-W3, -apple-system, "Helvetica Neue", Roboto, sans-serif;
  color: var(--tf-color-6);
  min-width: 0;
  text-align: left;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.comment .content .comment-body > p {
  margin: 0;
}

.comment .comment .reactions .buttons-container {
  border-top: 1px solid var(--tf-color-55);
}

.comment .comment .reactions .buttons button {
  height: 24px;
}

.comment .comment .reactions .buttons button ion-icon {
  font-size: 1.4rem;
}

.comment.compact .content .body p, .comment.compact-more .content .body p {
  line-height: 16px;
  height: 16px;
  overflow: hidden;
}

.comment .replies-container {
  margin: 8px 0 0px 29px;
}

.comment .replies-container .replies {
  list-style-type: none;
  padding: 0;
  border-left: 3px solid #ebebeb;
}

.comment .replies-container .replies .reply {
  padding: 4px 0 4px 6px;
}

.comment .replies-container .replies .reply .avatar-icon {
  width: 24px !important;
  height: 24px !important;
}

/**
 * 本SCSS変数定義ファイルは legacy 用です。
 * メールに影響するため編集禁止
 */
.tab-bar-menu {
  background-color: #333333;
  padding: 0;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
}

.tab-bar-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tab-bar-menu ul li {
  float: left;
  margin: 0;
  padding: 0;
  width: 33.3333333333%;
}

.tab-bar-menu .tab-bar-menu__button {
  display: inline-block;
  padding: 10px 14px;
  width: 100%;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  font-size: 10px;
  line-height: 1;
  color: #47b89a;
}

.tab-bar-menu .tab-bar-menu__button--active, .tab-bar-menu .tab-bar-menu__button:focus, .tab-bar-menu .tab-bar-menu__button:active {
  background-color: #c8dcff;
  color: #47b89a;
}

.tab-bar-menu .tab-bar-menu__button:hover {
  background-color: #c8dcff;
  color: #47b89a;
}

.tab-bar-menu .tab-bar-menu__button span {
  display: block;
  transform: scale(0.8);
  margin: 3px 0 0;
}

.tab-bar-menu .tab-bar-menu__button .material-icons {
  font-size: 14px;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .tab-bar-menu {
    max-width: 600px;
    position: static;
    margin-left: 0;
  }
  .tab-bar-menu .tab-bar-menu__button {
    padding: 9px 14px 10px;
  }
  .tab-bar-menu .tab-bar-menu__button span {
    display: inline-block;
    transform: scale(1);
  }
  .pins.container {
    padding-top: 40px;
  }
}
