$facebook-color: #fff;
$facebook-bgcolor: #3B5998;
$google-color: #fff;
$google-bgcolor: #DD4B39;
$github-color: #333;
$github-bgcolor: #transparent;
$twitter-color: #28A9E0;
$twitter-bgcolor: #transparent;
$pocket-color: #DD4B39;
$pocket-bgcolor: #transparent;
$hatena-color: #2C6DBD;
$hatena-bgcolor: #transparent;


.social-login-btn {
  margin: 5px 0;
  @media (max-width: $common-mobile-breakpoint) {
    padding: 0 5px;
  }

  .btn {
    text-align: left;
    line-height: 2em;
    width: 100%;
  }

  .icon {
    margin: 0 8px;
    padding: 4px;
    font-size: 20px;
  }
  &.facebook {
    .icon {
      color: $facebook-color;
      background-color: $facebook-bgcolor;
      font-size: 14px;
      margin: 2px 10px;
    }
  }
  &.google {
    .icon {
      color: $google-color;
      background-color: $google-bgcolor;
      font-size: 14px;
      border-radius: 16px;
      margin: 2px 10px;
    }
  }
  &.github {
    .icon {
      color: $github-color;
      background-color: $github-bgcolor;
    }
  }
  &.twitter {
    .icon {
      color: $twitter-color;
      background-color: $twitter-bgcolor;
    }
  }
  &.pocket {
    .icon {
      color: $pocket-color;
      background-color: $pocket-bgcolor;
    }
  }
  &.hatena {
    .icon {
      color: $hatena-color;
      background-color: $hatena-bgcolor;
      font-size: 19px;
    }
  }
}

.or-line {
  border-color: $color-6;
  margin: 24px 0;
  &::before {
    color: $color-6;
    content: "OR";
    display: block;
    width: 40px;
    margin-left: -20px;
    position: relative;
    top: -11px;
    left: 50%;
    background: $color-2;
    @media (min-width: $breakpoint-wide-desktop) {
      background-color: $wide-background-color;
    }
    font-size: 14px;
    text-align: center;
  }
}
