@import './app-variables-legacy';

#global-message {
  display: none;
  position: absolute;
  top: 0;
  width: 480px;
  margin-left: -240px;
  z-index: 999999;
  left: 50%;
  text-align: center;
  background-color: $color-11;
  color: $color-1;
  @media (max-width: $common-mobile-breakpoint) {
    width: 320px;
    margin-left: -160px;
  }

}

.page-title {
  border-bottom: 1px solid $tf-border-color;
  padding-bottom: 0.4em;
  line-height: 1.5;
  font-size: 1.5em;
  color: $tf-text-color;
}
.page-desc {
  font-size: 1.1em;
  margin: 0.5em 0 1em;
}

body {
  padding-top: $common-header-height;
  padding-bottom: 0px;
  margin: 0 auto;
  background-color: $tf-bg-color-desktop;
}

// ヘッダやフッタが不要な場合
// アプリからiframeで読み込まれることがある
html.content-only {
  body {
    padding: 0;
    margin: 0;
  }
}
body, .container {
  /*max-width: 1080px;*/
}

.jumbotron {
  color: $color-1;
  background-color: #4A4A4A;
  .top_lead, small {
    color: $color-8;
  }
  p {
    font-weight: inherit;
  }
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-right: 1rem;
  padding-left: 1rem;
}

/* Custom page header */
.header {
  padding-bottom: 1rem;
  border-bottom: .05rem solid #e5e5e5;
}

.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 3rem;
}

/* Custom page footer */
.footer {
  margin-top: 2em;
  padding: 2em 10px;
  color: $tf-color-1;
  background: $tf-global-header-bg-color;
  text-align: center;
  .site-footer-links {
    margin: 0 0 1em;
    padding: 0;
    list-style: none;
    li {
      display: inline-block;
      line-height: 16px;
      margin: 0;
      a, a:visited {
        color: $tf-color-secondary;
        text-decoration: underline;
        font-size: 12px;
        &:after {
          content: '';
          display: none;
        }
      }
      &:after {
        display: inline-block;
        content: '|';
        margin: 0 0.3em;
      }
      &:last-child:after {
        display: none;
        content: '';
        margin: 0;
      }
    }
  }
  small {
    color: $color-1;
  }
}
.container-narrow > hr {
  margin: 2rem 0;
}

/* Main marketing message and sign up button */
/*.jumbotron {
  text-align: center;
  border-bottom: .05rem solid #e5e5e5;
}*/

/* Supporting marketing content */
.marketing {
  margin: 3rem 0;
}
.marketing p + h4 {
  margin-top: 1.5rem;
}

img {
  max-width: 100%;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 48em) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 2rem;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  /*.jumbotron {
    border-bottom: 0;
  }*/
}

.page-size-medium {
  max-width: $common-page-size-medium;
}

.text-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ellipsis-nowrap {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// material-iconsがuser-agentを変更した状態でアクセスすると表示されない問題に対応
.material-icons {
  text-rendering: optimizeLegibility;
}

// loading dialog
.loading-dialog {
  display: none;
  background: rgba(#000, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  &__icon {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -18px 0 0 -18px;
    z-index: 1050;
    color: #fff;
  }
}

// 一旦無効化
// .app-layout {
//   width: 100%;
//   max-width: 1080px;
//   margin: 0 auto;
//   overflow: hidden;
//   .side-panel-left, .main-panel, .side-panel-right {
//     float: left;
//     padding: 0 0.9259259259259259%; // 10/1080
//     background-color: $color-4;
//   }
//   .side-panel-left, .side-panel-right {
//     width: 100%;
//     max-width: 25%; // 270/1080
//     color: white;
//     height: 600px;
//     @media (max-width: $common-mobile-breakpoint) {
//       display: none;
//     }
//   }
//   .main-panel {
//     width: 100%;
//     max-width: 50%;
//     margin: auto;
//     @media (max-width: $common-mobile-breakpoint) {
//       max-width: 100%;
//     }
//     .container {
//       max-width: 100%;
//     }
//   }
// }
// development
// .footer {
//   display: none;
// }

