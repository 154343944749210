@import "./variables";
@import "../../../../node_modules/@techfeed/client/src/app/components/entries/common";

$entry-base-font-size: 1rem;

$comment-author-name-font-size: 12px;
$comment-font-size: 12px;
$comment-updated-at-font-size: 10px;

$comment-bg-color: $tf-content-bg-color;

$height: 24px;
$padding-height: 4px;
$line-height: $height - $padding-height * 2;

.comments {
  margin-bottom: 8px;
  position: relative;
  .comments-header {
    position: absolute;
    top: -$height;
    margin: 0;
    padding: $padding-height 12px;
    border-bottom: none;
    height: $height;
    line-height: $line-height;
    background-color: $comment-bg-color;
    font-size: 11px;
    font-weight: bold;
  }
  .comment-list {
    padding: 0;
    list-style-type: none;
    margin: 0;
    .comment-listitem {
      margin-bottom: 1px;
    }
  }
  // bootstrapのhover時の下線を消す
  a {
    text-decoration: none !important;
  }
}

.comment {
  position: relative;
	background: $comment-bg-color;
  padding: 8px;
  border-top: 1px solid #d7d7d766;

  .comment-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    // 横に>を置くので、右paddingは不要
    .compact & {
      padding-right: 0;
    }
    >.avatar {
      // flex: 1 1 auto;
      margin-top: 4px;
      .avatar-icon {
        display: block;
        width: 32px;
        height: 32px;
        max-width: none;
      }
    }
    >.arrow-mark {
      // flex: 1 1 40px;
      flex-basis: 32px;
      align-self: center;
      text-align: center;
    }
  }
  .content {
    flex-grow: 1;
    margin: 4px　8px;
    margin-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .content-header {
      display: flex;
      align-items: center;
      margin: 0 0 4px;
      .author-name {
        display: flex;
        align-items: center;
        text-align: left;
        flex: 1 0 auto;
        overflow-x: hidden;
        .display-name {
          font-size: $comment-author-name-font-size;
          color: $tf-comment-author-name-color;
          font-weight: bold;
          display: inline-block;
        }
        .username {
          font-size: $comment-author-name-font-size;
          color: $tf-comment-author-id-color;
          display: inline-block;
          margin: 0 0 0 4px;
        }
      }
      .updated-at {
        font-size: $comment-updated-at-font-size;
        color: $tf-comment-meta-color;
        flex: 0 0 auto;
        text-align: right;
      }
      .action-button {
        $width: 20px;
        height: 14px;
        width: $width;
        flex: 0 0 $width;
        color: $color-8;
        font-size: 1.4rem;
        margin-top: 1px;
      }
    }
    .comment-body {
      font-family: $tf-font-family-normal-text;
      $line-height: $comment-font-size * 1.2;
      color: $tf-comment-text-color;
      min-width: 0;
      text-align: left;
      font-size: $comment-font-size;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      > p {
        margin: 0;
      }
    }
  }
  .comment .reactions {
    .buttons-container {
      border-top: 1px solid $tf-entry-border-color;
    }
    .buttons {
      button {
        height: 24px;
        ion-icon {
          font-size: 1.4rem;
        }
      }
    }
  }
  &.compact,
  &.compact-more {
    .content {
      .body {
        p {
          line-height: $line-height;
          height: $line-height;
          overflow: hidden;
        }
      }
    }
  }
  .replies-container {
    margin: 8px 0 0px 29px;
    .replies {
      list-style-type: none;
      padding: 0;
      border-left: 3px solid $color-14;
      .reply {
        padding: 4px 0 4px 6px;
        .avatar-icon {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}
