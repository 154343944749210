@import 'variables';

.tab-bar-menu {
  background-color: $color-3;
  // box-shadow: 0 -1px 1px #000;
  padding: 0;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      margin: 0;
      padding: 0;
      width: 33.3333333333%;
    }
  }
  .tab-bar-menu__button {
    display: inline-block;
    padding: 10px 14px;
    width: 100%;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    @include font_system;
    &--active, &:focus, &:active {
      background-color: $color-11;
      color: $color-1;
    }
    &:hover {
      background-color: $color-11;
      color: $color-1;
    }
    span {
      display: block;
      transform: scale(0.8);
      margin: 3px 0 0;
    }
    .material-icons {
      font-size: 14px;
      vertical-align: middle;
    }
  }
}
@media (min-width: $common-mobile-breakpoint) {
  .tab-bar-menu {
    max-width: 600px;
    position: static;
    margin-left: 0;
    .tab-bar-menu__button {
      padding: 9px 14px 10px;
      span {
        display: inline-block;
        transform: scale(1.0);
      }
    }
  }
  .pins {
    &.container {
      padding-top: 40px;
    }
  }
}
