@import 'variables';

/**
 * 雑多なCSSを集めておくファイル。
 * どこに分ければいいかわからないものはとりあえずここに書いておいて、カテゴライズできそうなら別ファイルに切り出す。
 */

 * {
   box-sizing: border-box;
 }

/**
 * target="_blank"なa要素に、自動的にアイコンを付与する
 */
a[target="_blank"]::after {
  margin-left: 2px;
  content: "　";
  display:inline-block;
  width: 14px;
  background: url('../../images/icons/material-design-icons/ic_open_in_new_white_24px.svg') no-repeat;
  background-size:contain;
  vertical-align:middle;
}
a[target="_blank"].no-icon::after {
  display: none;
  content: "";
}

.not-yet-implemented::after {
  content: "(TODO)"
}

.fixed {
  position: fixed;
}

// モバイルの場合は、リンクをクリックしたときに、「押した」というフィードバックがほしい
@media (max-width: $common-mobile-breakpoint) {
  a:active {
    background-color: $color-11;
  }
}
a:focus {
  text-decoration: none;
}

.margin-top-20 {
  margin-top: 20px;
}

// メール受信の注意書き
.mail-caution {
  text-align: left;
  margin-bottom: 25px;
  color: $color-8;
  font-size: 13px;

  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
